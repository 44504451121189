import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { default as BaseGoogleRecaptcha } from 'react-recaptcha';
import styled from 'styled-components';
import canUseDOM from '../../utils/canUseDOM';

const GoogleRecaptcha = styled(BaseGoogleRecaptcha)`
  transform: scale(0.91);
  transform-origin: 0 0;
  ${({ theme }) => `
    ${theme.breakPoints.up('sm')} {
      transform: scale(1);
    }
  `}
`;

const Recaptcha = ({
  className,
  onChange, // ant design form
}) => {
  const recaptchaVerifyCallback = useCallback((clientToken) => {
    if (onChange) {
      onChange(clientToken);
    }
  }, [onChange]);

  const recaptchaExpiredCallback = useCallback(() => {
    if (onChange) {
      onChange('');
    }
  }, [onChange]);

  if (!canUseDOM()) return null;
  
  return (
    <>
      <GoogleRecaptcha
        sitekey={process.env.GATSBY_SITE_RECAPTCHA_KEY}
        verifyCallback={recaptchaVerifyCallback}
        className={className}
        expiredCallback={recaptchaExpiredCallback}
      />
    </>
  );
};

Recaptcha.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default Recaptcha;
