import React from 'react';
import styled from 'styled-components';
import { isValidNumber } from 'libphonenumber-js';
import {
  Form,
  Input,
  Button as BaseButton,
  Typography,
  Tooltip,
  notification,
  message,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import SEO from '../components/SEO';
import MainContentWrapper from '../components/MainContentWrapper';
import Recaptcha from '../components/Recaptcha';

const { Title, Paragraph } = Typography;
const { TextArea } = Input;

const FormItem = styled(Form.Item)`
  display: none;
`;

const Button = styled(BaseButton)`
  margin-top: 1rem;
`;

const ContactUs = () => {
  const [form] = Form.useForm();

  const handleSubmit = async formValues => {
    let response = { status: 200 };
    const submittingMessageKey = 'submitting';
    let loadingFormSubmitMessageID = null;
    const errorMessage = {
      key: submittingMessageKey,
      message: 'Form submittion error',
      description: 'There was an error. Please try again.',
      placement: 'topRight'
    };
    const successMessage = {
      key: submittingMessageKey,
      message: 'Form submitted',
      description: `${formValues.name.trim()} we'll get back to you as soon as possible.`,
      placement: 'topRight',
      duration: 7
    };

    try {
      loadingFormSubmitMessageID = message.loading({ content: 'Submitting form', duration: 0 });
      response = await fetch('/.netlify/functions/sendEmail', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formValues),
      });
    } catch (error) {
      loadingFormSubmitMessageID();
      notification.error(errorMessage);
      return;
    }

    loadingFormSubmitMessageID();
    
    if (response.status === 500) {
      notification.error(errorMessage);
      return;
    }

    notification.success(successMessage);
    form.resetFields();
  };

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: '${label} is required.',
    whitespace: '${label} cannot be empty',
    types: {
      email: '${label} is not validate email.',
    },
  };
  /* eslint-enable no-template-curly-in-string */

  return (
    <>
      <SEO
        seo={{
          title: 'Home page',
          metas: [
            {
              name: 'description',
              content: 'DRD event decorations',
            },
          ],
        }}
      />
      <MainContentWrapper>
        <Title level={4}>Contact us</Title>
        <Paragraph>
          Please fill in the form below and we will get back to you.
        </Paragraph>
        <Form
          form={form}
          labelCol={{
            sm: { span: 6 },
            md: { span: 5 },
            lg: { span: 4 },
            xl: { span: 3 },
          }}
          wrapperCol={{
            sm: { span: 15 },
            md: { span: 10 },
          }}
          name="contactUs"
          initialValues={{ remember: true }}
          labelAlign="left"
          onFinish={handleSubmit}
          size="middle"
          validateMessages={validateMessages}
          scrollToFirstError
        >
          <Form.Item
            label="Full name"
            name="name"
            rules={[{ required: true, whitespace: true }]}
          >
            <Input
              placeholder="John Smith"
              autoComplete="name"
              suffix={
                <Tooltip title="E.g. John Smith">
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, type: 'email' }]}
          >
            <Input
              placeholder="john.smith@gmail.com"
              autoComplete="email"
              suffix={
                <Tooltip title="E.g. john.smith@gmail.com">
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label="Phone number"
            rules={[
              { required: true, whitespace: true },
              () => ({
                validator(_, value) {
                  const isPhoneNumberValid = isValidNumber(`+44${value}`);
                  if (!isPhoneNumberValid) {
                    return Promise.reject(
                      'Please enter your full contact number. e.g. 07123456789'
                    );
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              placeholder="07123456789"
              autoComplete="tel"
              addonBefore="+44"
              suffix={
                <Tooltip title="E.g. 02081234567">
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </Form.Item>
          <FormItem
            name="number"
            label="number"
            rules={[{ required: false, whitespace: false }]}
          >
            <Input
              placeholder="0"
              suffix={
                <Tooltip title="E.g. 0">
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </FormItem>
          <Form.Item
            name="message"
            label="Message"
            rules={[{ required: true, whitespace: true }]}
          >
            <TextArea
              placeholder="Message"
              autoSize={{ minRows: 5, maxRows: 5 }}
            />
          </Form.Item>
          <Form.Item
            name="reCAPTCHA"
            label="reCAPTCHA"
            rules={[{ required: true }]}
          >
            <Recaptcha />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              sm: { offset: 10 },
              md: { offset: 7 },
              xl: { offset: 6 },
            }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </MainContentWrapper>
    </>
  );
};

export default ContactUs;
